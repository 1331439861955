
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import CcsMultiselect from "@/components/ccs-multiselect/index.vue";
import { getDesignById, getDesigns } from "@/core/data/design";
export default defineComponent({
  name: "DesignMultiselect",
  components: {
    CcsMultiselect,
  },
  emits: ["update:modelValue"],
  props: {
    modelValue: {
      required: false,
    },
    selectedValue: {
      required: false,
    },
    loading: {
      required: false,
    },
    isEdit: {
      required: false,
      defualt: true,
    },
    options: {
      type: [Array],
      required: false,
      default: () => [],
    },
    party: {
      required: false,
      default: "",
    },
  },
  setup(props, { emit }) {
    const reRender = ref<any>(true);
    const selectedItem = ref<any>(props.modelValue);
    const designList = ref<Array<any>>([]);
    const designSelectedValue = ref<any>(props.selectedValue);
    const party = ref<any>(props.party);
    const designListComputed = computed(() => {
      return designList.value.map((item) => ({
        ...item,
        name: item.code,
        value: item.id,
      }));
    });
    const searchDesign = (name = "") => {
      return new Promise((res) => {
        getDesigns(1, { name, party_id: party.value }).then((resObj: any) => {
          designList.value[0] = { ...designSelectedValue.value };

          designList.value.splice(
            !designList.value[0].id ? 0 : 1,
            designList.value.length,
            ...resObj.data.filter((item) => item.id != designList.value[0].id)
          );
          designList.value = [...designList.value];
          if (
            selectedItem.value &&
            !designList.value.some((item) => item.id === selectedItem.value)
          ) {
            getDesignById(selectedItem.value).then((resObj: any) => {
              designList.value.push(resObj);
            });
          }
          console.log(designList.value);
          res(true);
        });
      });
    };

    const selectDesign = (data) => {
      designSelectedValue.value = data;
      emit("update:modelValue", designSelectedValue.value?.id);
    };
    watch(
      () => props.party,
      (newVal: any, oldVal) => {
        party.value = newVal;
        searchDesign().then(() => {
          reRender.value = false;
          setTimeout(() => {
            reRender.value = true;
          }, 100);
        });
      }
    );
    watch(
      () => props.isEdit,
      (newVal: any, oldVal) => {
        searchDesign().then(() => {
          reRender.value = false;
          setTimeout(() => {
            reRender.value = true;
          }, 100);
        });
      }
    );
    watch(
      () => props.modelValue,
      (newVal: any, oldVal) => {
        selectedItem.value = props.modelValue;
        reRender.value = false;
        setTimeout(() => {
          reRender.value = true;
        }, 100);
      }
    );
    watch(
      () => props.selectedValue,
      (newVal: any, oldVal) => {
        designSelectedValue.value = props.selectedValue;
        searchDesign().then(() => {
          reRender.value = false;
          setTimeout(() => {
            reRender.value = true;
          }, 100);
        });
      }
    );
    onMounted(() => {
      searchDesign();
    });
    return {
      selectedItem,
      designListComputed,
      searchDesign,
      selectDesign,
      reRender,
    };
  },
});
