
import { defineComponent, ref, onMounted, computed } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import router from "@/router";
import Productions, {
  exportProductionData,
  exportPDFProductions,
} from "@/core/data/production";
import {
  Production,
  getProductions,
  deleteProduction,
} from "@/core/data/production";
import moment from "moment";
import store from "@/store";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getUserById, getUsers } from "@/core/data/users";
import { getError } from "@/core/helpers/utils";
import { PERMISSION_MODULE_NAME } from "@/core/data/permission";
import DesignMultiselect from "@/components/all-multiselect/design-multiselect.vue";
import PartyMultiselect from "@/components/all-multiselect/party-multiselect.vue";
import SeasonMultiselect from "@/components/all-multiselect/season-multiselect.vue";

export default defineComponent({
  name: "Production-listing",
  components: {
    Datatable,
    DesignMultiselect,
    PartyMultiselect,
    SeasonMultiselect,
  },
  setup() {
    const checkedProduction = ref([]);
    const selectedStatus = ref("");

    let selectedItem = ref({});
    let selectedUser = ref();

    const total = ref<any>(0);
    const currentPage = ref<any>(1);
    const showSearchPanel = ref<boolean>(true);
    const selectAllProduction = ref<boolean>(false);
    const isLoading = ref<boolean>(true);
    const search = ref<string>("");
    const tableData = ref<Array<Production>>(Productions);
    const userList = ref<any>({});
    const searchData = ref<any>({});
    const userOptionList = ref<any>([]);
    const userIdList = ref<Array<any>>([]);
    const branch = ref([]);
    const course = ref([]);
    const universiteies = ref([]);
    const getListItems = function (pageNumber) {
      console.log("selectedUser.value", selectedUser.value);
      isLoading.value = true;
      if (pageNumber) {
        currentPage.value = pageNumber;
      }

      localStorage.setItem(
        "ProductionListAPiFilterData",
        JSON.stringify({
          currentPage: currentPage.value,
          searchData: searchData.value,
          selectedStatus: selectedStatus.value,
          selectedUser: selectedUser.value,
        })
      );
      return new Promise((res, rej) => {
        getProductions(
          currentPage.value,
          searchData.value,
          selectedStatus.value,
          selectedUser.value
        ).then((resObj: any) => {
          isLoading.value = false;
          total.value = resObj.count;
          tableData.value.splice(0, tableData.value.length, ...resObj.data);
          console.log("tableData", tableData.value);
          let promiseList: any = [];
          tableData.value.forEach((item) => {
            if (userIdList.value.indexOf(item.created_by) == -1) {
              userIdList.value.push(item.created_by);
              promiseList.push(getUserName(item.created_by));
            }
            Promise.all(promiseList).then(() => {
              res(resObj);
            });
          });
          console.log("tableData", tableData.value);
        });
      });
    };
    const getUserName = async function (id) {
      if (!userList.value[id]) {
        let userData = await getUserById(id);
        userList.value[id] = userData;
      }
    };
    onMounted(() => {
      setCurrentPageBreadcrumbs("Productions Listing", ["Apps", "Productions"]);
      var productionListAPiFilterData: any = localStorage.getItem(
        "ProductionListAPiFilterData"
      );
      if (productionListAPiFilterData) {
        setTimeout(() => {
          productionListAPiFilterData = JSON.parse(productionListAPiFilterData);
          currentPage.value = productionListAPiFilterData.currentPage;
          searchData.value = productionListAPiFilterData.searchData;
          selectedStatus.value = productionListAPiFilterData.selectedStatus;
          selectedUser.value = productionListAPiFilterData.selectedUser;
          getListItems(currentPage.value);
        }, 500);
      } else {
        getListItems(1);
      }
    });

    const clearFilter = () => {
      currentPage.value = 1;
      searchData.value = {};
      selectedStatus.value = "";
      selectedUser.value = "";
      getListItems(1);
    };

    const userListComputed = computed(() => {
      return [
        { id: "", name: "All" },
        ...userOptionList.value.map((item) => ({
          name: item.name,
          ...item,
        })),
      ];
    });

    const asyncUser = (query) => {
      console.log("query", query);
      getUsers(1, query).then((resObj: any) => {
        userOptionList.value.splice(
          0,
          userOptionList.value.length,
          ...resObj.data
        );
      });
    };
    const deleteFewSites = () => {
      checkedProduction.value.forEach((item) => {
        deleteProduction(item);
      });
      checkedProduction.value.length = 0;
    };
    const exportFewSites = () => {
      exportProductionData(searchData.value).then(() => {
        Swal.fire("Export!", "Your Production has been exported.", "success");
      });
    };
    const exportPDFProductionsHanlder = () => {
      exportPDFProductions(
        selectAllProduction.value ? "all" : checkedProduction.value.join(",")
      ).then(() => {
        Swal.fire("Export!", "Your Production has been exported.", "success");
      });
    };
    const deleteProductionItem = (item) => {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          deleteProduction(item)
            .then(() => {
              Swal.fire(
                "Deleted!",
                "Your Production has been deleted.",
                "success"
              );
              getListItems(currentPage.value);
            })
            .catch((err) => {
              Swal.fire({
                html: getError(err),
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              });
            });
        }
      });
    };
    const searchItems = () => {
      getListItems(1);
    };

    const setSelectedItem = (item) => {
      selectedItem.value = item;
    };

    const addProduction = (id) => {
      if (id) {
        router.push({ name: "apps-edit-production", params: { id: id } });
      } else {
        router.push({ name: "apps-add-production" });
      }
    };
    const stockProductionItem = (id) => {
      router.push({ name: "apps-stock-production", params: { id: id } });
    };
    const designMaterialProductionLanding = (id, designId) => {
      router.push({
        name: "apps-design-production-landing",
        params: { id: id, designId: designId },
      });
    };
    const fusingProductionLanding = (id, designId) => {
      router.push({
        name: "apps-fusing-production",
        params: { id: id, designId: designId },
      });
    };
    const onPageChange = (pageNumber) => {
      return getListItems(pageNumber);
    };

    const userData = computed(() => {
      return store.getters.currentUser;
    });

    const permissionData = computed(() => {
      return store.getters.currentPermission;
    });
    const hasCreate = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.PRODUCTION].create
      );
    });
    const hasEdit = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.PRODUCTION].update
      );
    });
    const hasDelete = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.PRODUCTION].delete
      );
    });
    const hasStock = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.PRODUCTION_STOCK].read
      );
    });
    const hasCost = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.PRODUCTION].show_cost
      );
    });
    const hasPeelingAndCutting = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.PEELING].read ||
        permissionData.value[PERMISSION_MODULE_NAME.CUTTING].read
      );
    });
    const hasFusing = computed(() => {
      return (
        userData.value.role == "ADMIN" ||
        permissionData.value[PERMISSION_MODULE_NAME.FUSING].read
      );
    });
    const tableHeader = computed(() => {
      let columnsArr = [
        {
          name: " ",
          key: "checkbox",
          sortable: false,
        },
        {
          name: "Design",
          key: "design_data",
          sortable: false,
        },
        {
          name: "Season",
          key: "season_data",
          sortable: false,
        },
        {
          name: "Image",
          key: "image",
          sortable: false,
        },
        {
          name: "Qty",
          key: "qty",
          sortable: false,
        },
        {
          name: "Date In",
          key: "dateIn",
          sortable: false,
        },
      ];
      if (
        hasEdit.value ||
        hasDelete.value ||
        hasFusing.value ||
        hasPeelingAndCutting.value
      ) {
        columnsArr.push({
          name: "Actions",
          key: "actions",
          sortable: false,
        });
      }
      return columnsArr;
    });
    return {
      addProduction,
      setSelectedItem,
      selectedItem,
      tableData,
      tableHeader,
      deleteProduction,
      getProductions,
      deleteProductionItem,
      search,
      searchItems,
      checkedProduction,
      deleteFewSites,
      moment,
      total,
      currentPage,
      isLoading,
      onPageChange,
      userData,
      hasCreate,
      hasEdit,
      hasDelete,
      hasStock,
      hasPeelingAndCutting,
      hasFusing,
      selectedStatus,
      getUserName,
      userList,
      userListComputed,
      selectedUser,
      asyncUser,
      exportFewSites,
      exportPDFProductionsHanlder,
      selectAllProduction,
      showSearchPanel,
      searchData,
      branch,
      universiteies,
      course,
      stockProductionItem,
      designMaterialProductionLanding,
      fusingProductionLanding,
      clearFilter,
      hasCost,
    };
  },
});
